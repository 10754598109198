import { DialogTitle, IconButton } from '@material-ui/core';
import { Mic } from '@material-ui/icons';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  atom,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState
} from 'recoil';
import { getAuthUser } from '../ducks/auth';
import { parentalControlState } from '../recoil';
import { VoiceInputDialog } from './VoiceInputDialog';

const slaaskVisibility = atom({
  key: 'slaaskVisibility',
  default: false
});

export function Slaask({ location }: RouteComponentProps) {
  const { initialized, userInfo } = useSelector(state => state.auth);
  const authUser = useSelector(getAuthUser);
  const authUserRef = React.useRef(authUser);
  authUserRef.current = authUser;

  const currentPageRef = React.useRef('');
  currentPageRef.current =
    window.location.origin + location.pathname + location.hash;

  const loadable = useRecoilValueLoadable(parentalControlState);
  const enablePublish =
    loadable.state === 'hasValue' ? loadable.contents?.enablePublish : false;

  window._slaaskSettings = {
    key: process.env.REACT_APP_SLAASK_KEY || '',
    ...(window._slaaskSettings || {}),
    identify() {
      const user = authUserRef.current;
      const plan = user?.enterprise
        ? 'enterprise'
        : user?.plans?.canWatchAllVideo
        ? 'paid'
        : 'free';
      const registered = user?.createdAt?.toMillis?.();
      return user
        ? {
            id: user.uid, // User ID (Mandatory)
            name: user.displayName, // Your user's full name
            // email: ____,        // Your user's email address
            current_page: currentPageRef.current, // String
            user_page: `https://www.hackforplay.xyz/users/${user.uid}`,
            avatar: user.iconUrl, // String
            plan,
            build_number: process.env.BUILD_NUMBER,
            enable_publish: Boolean(enablePublish),
            // utm_source: ____,   // String
            registered // Number
          }
        : null;
    }
  };

  React.useEffect(() => {
    window._slaask?.updateContact();
  }, [currentPageRef.current]);

  React.useEffect(() => {
    if (!initialized) return; // ログイン中は表示しない
    if (!userInfo || authUser) {
      // ログインしていないか、あるいはユーザー情報の取得が終わったら表示する
      window._slaask?.identifyContact();
    }
  }, [initialized, userInfo, authUser]);

  // 決まったメッセージに自動で答える。ユーザー自身のチャットウィンドウに書いて送信する（Slack にも送信される）
  React.useEffect(() => {
    const messages = new Map([
      ['かなざわ', '↓クリックしてね↓\nhttps://whereby.com/hackforplay'],
      ['金沢', '↓クリックしてね↓\nhttps://whereby.com/hackforplay']
    ]);
    const handler = (e: SlaaskSentMessageEvent) => {
      const response = messages.get(e.detail.message || '');
      if (response) {
        window._slaask?.sendMessageAsContact(response);
      }
      // ユーザーの情報を更新する (複数のタブを開いている時のため)
      window._slaask?.updateContact();
    };
    document.addEventListener('slaask.sent_message', handler, {
      passive: true
    });
    return () => {
      document.removeEventListener('slaask.sent_message', handler);
    };
  }, []);

  // Helpfeel Element との連携
  const setVisibility = useSetRecoilState(slaaskVisibility);
  React.useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      const { data } = event;
      if (typeof data !== 'object') return;
      const { action, id, source } = data;
      if (
        source === 'helpfeel' &&
        action === 'show-article' &&
        id === '60a5134ac6a76d0022dc74d3'
      ) {
        setVisibility(true);
      }
    };
    window.addEventListener('message', onMessage, { passive: true });
    return () => window.removeEventListener('message', onMessage);
  }, []);

  return (
    <>
      <SlaaskVoiceInput />
      <SlaaskObserver />
    </>
  );
}

function SlaaskObserver() {
  const [_visibility, setVisibility] = useRecoilState(slaaskVisibility);
  const visibilityRef = React.useRef(_visibility);
  visibilityRef.current = _visibility;

  const update = useCallback(() => {
    return; // ずっと表示し続ける
    const visibility = visibilityRef.current;
    const elements = document.querySelectorAll<HTMLElement>(
      '#slaask-widget,#slaask-button,#slaask-message'
    ); // visibility を制御すべき要素
    for (const element of Array.from(elements)) {
      element.style.visibility = visibility ? 'visible' : 'hidden';
    }
    if (visibility) {
      window._slaask?.show(); // チャット画面を開く
    }
  }, []);
  useEffect(update, [_visibility]);

  useEffect(() => {
    document.addEventListener('slaask.ready', update, { passive: true }); // ロード時に非表示にする
    const timerId = window.setInterval(update, 1000); // １秒毎にずっと実行し続ける
    const handleOpen = () => {
      setVisibility(true);
    };
    document.addEventListener('slaask.open', handleOpen);
    const handleClose = () => {
      setVisibility(false);
    };
    document.addEventListener('slaask.close', handleClose);
    return () => {
      document.removeEventListener('slaask.ready', update);
      document.removeEventListener('slaask.open', handleOpen);
      document.removeEventListener('slaask.close', handleClose);
      window.clearInterval(timerId);
    };
  }, []);

  return null;
}

function SlaaskVoiceInput() {
  const visibility = useRecoilValue(slaaskVisibility);

  // メニューの基準位置をウィジェットの右下に合わせる
  const rootRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (!visibility) return;
    const id = setInterval(() => {
      // z-index を低くしてオーバーレイできるようにする
      const widget = document.getElementById('slaask-widget');
      if (!widget) return;
      widget.style.zIndex = '1299';

      const el = rootRef.current;
      if (!el) return;
      const rect = widget.getBoundingClientRect();
      el.style.left = `${rect.right}px`;
      el.style.top = `${rect.bottom}px`;
    }, 250);
    return () => {
      clearInterval(id);
    };
  }, [visibility]);

  // 入力文字が空であることを確かめる
  const [inputEmpty, setInputEmpty] = React.useState(false);
  React.useEffect(() => {
    const handle = setInterval(() => {
      const text = localStorage.getItem('slaask-widget-typed-text');
      setInputEmpty(!text || text === '""');
    }, 1000);
    return () => {
      clearInterval(handle);
    };
  }, []);

  const [dialogOpened, setDialogOpened] = React.useState(false);
  const handleSend = React.useCallback((result: string) => {
    result = ':speech_balloon: ' + result; // 音声入力で送ったことがわかるようにする
    window._slaask?.sendMessageAsContact(result);
  }, []);

  return (
    <div
      id="slaask-voice-input-root"
      style={{
        position: 'fixed',
        display: visibility && inputEmpty ? 'block' : 'none',
        marginLeft: -100,
        marginTop: -40,
        zIndex: 1300
      }}
      ref={rootRef}
    >
      <IconButton size="small" onClick={() => setDialogOpened(true)}>
        <Mic color="disabled" />
      </IconButton>
      <VoiceInputDialog
        open={dialogOpened}
        onClose={() => setDialogOpened(false)}
        onResult={handleSend}
      >
        <DialogTitle>音声入力で開発者に質問する</DialogTitle>
      </VoiceInputDialog>
    </div>
  );
}
